
import { Vue, Component,Watch } from "vue-property-decorator";
import initNECaptcha from '@/lib/yidun-captcha.js'


@Component({
  components: {},
})
export default class LoginPanel extends Vue {
  private loginForm: Record<string, string> = {
    account: '',
    password: '',
    captcha: ''
  } 
  private loginRules = {
    account: [{ required: true, message: '请输入登录账号', trigger: 'blur' }],
    password: [{ required: true, message: '请输入登录密码', trigger: 'blur' },
          {
            min: 6,
            max: 20,
            message: '长度在 6 到 20 个字符',
            trigger: 'blur'
          }]
  }
  private loading: boolean = false
  private passwordType = 'password'
  private redirect = '/'
  private captchaIns: any = null

  private handleLogin() {
    const loginFormEl : any = this.$refs.loginForm
    loginFormEl.validate((valid: any) => {
      if (valid) {
        this.loading = true
        this.$store.dispatch('user/login', this.loginForm)
          .then(rep => {
            this.$router.push({ path: this.redirect || '/' })
            this.captchaIns.destroy()
            this.loading = false
          }).catch(() => {
            this.loading = false
            this.captchaIns.refresh()
            this.loginForm.captcha = ''
          })
      } else {
        return false
      }
    })
  }

  private captchInit() {
    var that = this
    initNECaptcha({
      captchaId: '9bb90f17b2e045a5a3f811225184d20b',
      element: '#captcha',
      mode: 'float',
      apiVersion: 2,
      onVerify: (err: any, data: any) => {
        // 当验证失败时, 内部会自动 refresh 方法, 无需手动再调用一次
        if (err) return
        that.loginForm.captcha = data.validate
      }
    }, function onload (instance: any) {
      that.captchaIns = instance
    }, function onerror (err: any) {
      // 初始化失败后触发该函数, err 对象描述当前错误信息
      console.log(err)
    })
  }

  private mounted() {
    this.captchInit()
  }

  @Watch("$route.query", { deep: true, immediate: true })
  private routeChanged(query: any) {
    this.redirect = query && query.redirect
  }

  private showPwd() {
    if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        let pwdInputEI :any = this.$refs.password
        pwdInputEI.focus()
      })
  }

}
